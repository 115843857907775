import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/seo"
import { Row, Col, Card } from "react-bootstrap";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

// eslint-disable-next-line
export const ReferenzenPageTemplate = ({
    refTitle,
    refHeading,
    // refDescriptions,
    // referenzenImage,
    refImage1,
    refImage2,
    refImage3,
    refImage4,
    referenzen,
}) => {
    // const refImage = getImage(referenzenImage) || referenzenImage;
    // const alt = `Gastrokalk Kunden Referenzen`;

    return (
        <>
            <Row>
                <Col className="col-lg-8 pb-2">
                    <h1 className="page-title title-border">
                        {refTitle}
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col className="col-lg-8 offset-lg-2">
                    <Row>
                        <Col>
                            <Row className="justify-content-md-center sk-images referenz-ani-container">
                                <Col>
                                    <div className="flyin-img-1"><PreviewCompatibleImage imageInfo={refImage1} /></div>
                                </Col>
                                <Col>
                                    <div className="flyin-img-2"><PreviewCompatibleImage imageInfo={refImage2} /></div>
                                </Col>
                                <Col lg={7} sm={1}>
                                    <div className="flyin-text-1">Gastrokalk im Betrieb</div>
                                </Col>
                            </Row>
                            <Row className="justify-content-md-center sk-images referenz-ani-container">
                                <Col lg={4} sm={1} className="referenz-ani-container">
                                    <div className="flyin-text-2">Erfolg in der Tasche</div>
                                </Col>
                                <Col>
                                    <div className="flyin-img-1"><PreviewCompatibleImage imageInfo={refImage3} /></div>
                                </Col>
                                <Col>
                                    <div className="flyin-img-4"><PreviewCompatibleImage imageInfo={refImage4} /></div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    
                    <div className="content">
                        <h2 className="header-referenzen">
                            {refHeading}
                        </h2>
                    </div>
                        
                    <Row xs={1} md={3} className="g-4 justify-content-md-center">
                        {referenzen.map((referenz, index) => (
                            <Col key={index}>
                                <Card key={index} border="dark" className="mb-3 border-1 rounded-2 card-referenz">
                                    <Card.Header className="card-header-referenzen">
                                        <div>{referenz.refName}</div>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="pb-2" style={{textAlign: "center"}}><PreviewCompatibleImage imageInfo={referenz.refLogo} /></div>
                                        <div className="pb-2">{referenz.refDescription}</div>
                                        <div className="pb-2"><strong>Betriebsart:</strong> {referenz.refCategory}</div>
                                        <div className="pb-2"><strong style={{ fontVariantCaps: "small-caps" }}>Programme:</strong> {referenz.refProduct}</div>
                                        <div className="pb-2"><strong style={{ fontVariantCaps: "small-caps" }}>Zitat:</strong>  {referenz.refQuote}</div>
                                    </Card.Body>
                                    <Card.Footer className="text-primary">{referenz.refLocation}</Card.Footer>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </>
    );
};

const ReferenzenPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
    const location = "referenzen"

    return (
        <Layout location={location}>
            <ReferenzenPageTemplate
                refTitle={frontmatter.refTitle}
                refHeading={frontmatter.refHeading}
                refDescriptions={frontmatter.refDescriptions}
                referenzenImage={frontmatter.referenzenImage}
                refImage1={frontmatter.refImage1}
                refImage2={frontmatter.refImage2}
                refImage3={frontmatter.refImage3}
                refImage4={frontmatter.refImage4}
                referenzen={frontmatter.referenzen}
            />
        </Layout>
    );
};

ReferenzenPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
        }),
    }),
};

export default ReferenzenPage;

export const Head = () => <Seo title="Referenzen" />

export const referenzenPageQuery = graphql`
  query ReferenzenPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        refTitle
        refHeading
        refDescriptions {
          refDescription1
          refDescription2
          refDescription3
        }
        referenzenImage {
          childImageSharp {
            gatsbyImageData(quality: 100,  placeholder: BLURRED,layout: FULL_WIDTH)
          }
        }
        refImage1 {
            alt
            image {
                childImageSharp {
                gatsbyImageData(width: 175, quality: 92, placeholder: BLURRED, layout: CONSTRAINED)
                }
            }
        }
        refImage2 {
            alt
            image {
                childImageSharp {
                gatsbyImageData(width: 175, quality: 92, placeholder: BLURRED, layout: CONSTRAINED)
                }
            }
        }
        refImage3 {
            alt
            image {
                childImageSharp {
                gatsbyImageData(width: 175, quality: 92, placeholder: BLURRED, layout: CONSTRAINED)
                }
            }
        }
        refImage4 {
            alt
            image {
                childImageSharp {
                gatsbyImageData(width: 350, quality: 92, placeholder: BLURRED, layout: CONSTRAINED)
                }
            }
        }
        referenzen {
          refName
          refCategory
          refDescription
          refLogo {
            childImageSharp {
                gatsbyImageData(width: 135, quality: 92, placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
          refProduct
          refLocation
          refQuote
        }
      }
    }
  }
`;
